<app-content-wrapper [title]="'title.profile' | translate">
    <div class="aa-flex" content>
        <form class="aa-form" style="width: 300px" [formGroup]="profileForm">
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.firstName' | translate}}</mat-label>
                    <input formControlName="firstName" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.lastName' | translate}}</mat-label>
                    <input formControlName="lastName" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.email' | translate}}</mat-label>
                    <input type="email" formControlName="email" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.phone' | translate}}</mat-label>
                    <input type="number" formControlName="phone" maxlength="50" matInput>
                </mat-form-field>
            </p>

            <button type="button" mat-raised-button color="primary" (click)="saveProfile()" [disabled]="profileForm.invalid || profileForm.pristine">
                <mat-icon>save</mat-icon>
                {{'form.save' | translate}}
            </button>
        </form>

        <form class="aa-form" style="width: 300px" [formGroup]="passwordForm">
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.passwordOld' | translate}}</mat-label>
                    <input type="password" formControlName="password" matInput #pwOld>
                    <mat-icon inline matSuffix (click)="pwOld.type = pwOld.type == 'password' ? 'text' : 'password'"
                        style="cursor:pointer">{{pwOld.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.passwordNew' | translate}}</mat-label>
                    <input type="password" formControlName="passwordNew" matInput #pwNew>
                    <mat-icon inline matSuffix (click)="pwNew.type = pwNew.type == 'password' ? 'text' : 'password'"
                        style="cursor:pointer">{{pwNew.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </p>

            <button type="button" mat-raised-button color="primary" (click)="changePassword()"
                [disabled]="passwordForm.invalid || passwordForm.pristine">
                <mat-icon>key</mat-icon>
                {{'form.changePassword' | translate}}
            </button>
        </form>
    </div>
</app-content-wrapper>
