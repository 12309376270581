import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-safe-guard',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    ContentWrapperComponent,
  ],
  templateUrl: './safe-guard.component.html',
  styleUrl: './safe-guard.component.scss',
})
export class SafeGuardComponent {
  safeGuardQR: string = environment.production
    ? '/assets/qrcode/safeguard-live.png'
    : '/assets/qrcode/safeguard-dev.png';
}
