import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { MiscService } from '../../services/misc.service';
import { Provider } from '../../models/provider';
import { ProviderComponent } from './provider/provider.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CountryPipe } from '../../pipes/country.pipe';

@Component({
  selector: 'app-providers',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    TranslateModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    CountryPipe,
  ],
  templateUrl: './providers.component.html',
  styleUrl: './providers.component.scss',
})
export class ProvidersComponent {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<Provider> = new MatTableDataSource();
  displayedColumns = ['name', 'street', 'city', 'country'];
  activatedRow: any;

  constructor(
    private api: ApiService,
    private misc: MiscService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadProviders();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadProviders() {
    this.api.PROVIDERS_GetProviders().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.dataSource = new MatTableDataSource(res.body);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  addProvider() {
    const dialogRef = this.dialog.open(ProviderComponent, {
      maxHeight: '80vh',
    });
    dialogRef
      .afterClosed()
      .subscribe((res) => (res?.reload ? this.loadProviders() : ''));
  }

  editProvider(provider: Provider) {
    const dialogRef = this.dialog.open(ProviderComponent, {
      data: {
        provider,
      },
      maxHeight: '80vh',
    });

    dialogRef
      .afterClosed()
      .subscribe((res) => (res?.reload ? this.loadProviders() : ''));
  }
}
