import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { RouterModule } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiService } from '../../services/api.service';
import { Contact } from '../../models/contact';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactComponent } from './contact/contact.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CountryPipe } from '../../pipes/country.pipe';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    ContentWrapperComponent,
    CountryPipe,
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ['name', 'street', 'city', 'country'];
  dataSource: MatTableDataSource<Contact> = new MatTableDataSource();

  constructor(private api: ApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.api.CONTACTS_GetContacts().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.dataSource = new MatTableDataSource(res.body);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
        }
      },
      error: (err) => {},
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openContactModal(contact?: Contact) {
    const dialogRef = this.dialog.open(ContactComponent, {
      data: {
        contact,
      },
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe((res: { reload: boolean }) => {
      res?.reload ? this.ngOnInit() : '';
    });
  }
}
