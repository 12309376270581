import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../../components/content-wrapper/content-wrapper.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Provider } from '../../../models/provider';
import { ApiService } from '../../../services/api.service';
import { Location } from '../../../models/location';
import { Product } from '../../../models/product';
import { Rack } from '../../../models/rack';
import { Contact } from '../../../models/contact';
import { MiscService } from '../../../services/misc.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductsComponent } from '../../products/products.component';
import { RacksComponent } from '../../racks/racks.component';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import Countries from '../../../../assets/countryCodes.json';
import { CountryPipe } from '../../../pipes/country.pipe';
@Component({
  selector: 'app-location',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatAutocompleteModule,
    ContentWrapperComponent,
    ProductsComponent,
    RacksComponent,
  ],
  providers: [CountryPipe],
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss',
})
export class LocationComponent {
  @ViewChild('productList') productList!: ProductsComponent;
  location?: Location;
  products?: Product[];
  contacts: Contact[] = [];
  providers: Provider[] = [];
  locationForm = this.fb.group({
    id: 0,
    locationId: ['', [Validators.required, Validators.maxLength(50)]],
    street: ['', [Validators.required, Validators.maxLength(50)]],
    name: ['', [Validators.required, Validators.maxLength(50)]],
    city: ['', [Validators.required, Validators.maxLength(50)]],
    country: ['', Validators.required],
    language: ['en-GB', Validators.required],
    adminPassword: ['', [Validators.required, Validators.maxLength(200)]],
    intervalPasswordA: ['', [Validators.required, Validators.maxLength(200)]],
    intervalPasswordB: ['', [Validators.required, Validators.maxLength(200)]],
    contactId: [0, [Validators.required, Validators.min(1)]],
    providerId: 0,
  });

  isBusy = true;
  filteredCountries: any[] = [];
  displayedColumns: string[] = ['id', 'name'];
  activatedRackId?: number;
  showPwdA: boolean = false;
  showPwdB: boolean = false;

  languages = [
    {
      id: 'de-DE',
      name: 'Deutsch',
    },
    {
      id: 'en-GB',
      name: 'Englisch',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<LocationComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public countryPipe: CountryPipe,
    @Inject(MAT_DIALOG_DATA) public data?: Location
  ) {}
  async ngOnInit(event?: any) {
    console.log('Event: ', event);
    const locationId = this.data?.id;
    this.api.PROVIDERS_GetProviders().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.providers = res.body;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });

    this.api.CONTACTS_GetContacts().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.contacts = res.body;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });

    if (locationId) {
      this.api.LOCATIONS_GetLocation(locationId).subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.location = res.body;
            console.log(this.location);

            this.locationForm.patchValue(this.location);
            if (this.location.racks) {
              if (event != null) {
                const _rack = this.location?.racks?.find((f) => f.id == event);
                this.changeProducts(_rack ?? this.location?.racks[0]);
              } else {
                this.changeProducts(this.location?.racks[0]);
              }
              console.log('LOCATION UPDATED');
            }
            this.isBusy = false;
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: () => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
    } else {
      this.isBusy = false;
    }
  }

  filterCountries(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredCountries = Countries.filter((country) =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  changeProducts(rack: Rack) {
    this.activatedRackId = rack.id;
    this.products = rack.products;
  }

  save() {
    if (this.location?.id) {
      this.api
        .LOCATIONS_PutLocation(this.locationForm.value as Location)
        .subscribe({
          next: (res) => {
            if (res.ok) {
              if (res.ok) {
                this.dialogRef.close({ reload: true });
                this.misc.showInfo(this.translate.instant('success.save'));
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    } else {
      this.api
        .LOCATIONS_PostLocation(this.locationForm.value as Location)
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(
                this.translate.instant('success.addLocation', {
                  location: this.locationForm.get('name')?.value,
                })
              );
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    }
  }

  deleteLocation() {
    if (!this.location) return;
    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteLocation'),
          message: this.translate.instant('form.deleteLocationInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api.LOCATIONS_DeleteLocation(this.location!.id).subscribe({
            next: (res) => {
              if (res.ok) {
                this.dialogRef.close({ reload: true });
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            },
            error: () => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
        }
      });
  }
}
