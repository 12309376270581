import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../../components/content-wrapper/content-wrapper.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { Provider } from '../../../models/provider';
import { MatInputModule } from '@angular/material/input';
import { MiscService } from '../../../services/misc.service';
import { User } from '../../../models/user';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import Countries from '../../../../assets/countryCodes.json';
import { CountryPipe } from '../../../pipes/country.pipe';

@Component({
  selector: 'app-provider',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    TranslateModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [CountryPipe],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.scss',
})
export class ProviderComponent {
  providerForm = this.fb.group({
    id: 0,
    name: ['', [Validators.required, Validators.maxLength(50)]],
    street: ['', [Validators.required, Validators.maxLength(50)]],
    city: ['', [Validators.required, Validators.maxLength(50)]],
    country: ['', Validators.required],
  });

  isBusy = true;
  users: User[] = [];
  activatedRow: any;
  displayedColumns = ['name', 'email', 'phone', 'actions'];
  filteredCountries: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { provider?: Provider },
    public dialogRef: MatDialogRef<ProviderComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public countryPipe: CountryPipe
  ) {}

  ngOnInit(): void {
    if (this.data?.provider) {
      this.providerForm.patchValue(this.data.provider);
      //this.loadUsers();
      this.isBusy = false;
    } else {
      this.isBusy = false;
    }
  }

  filterCountries(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredCountries = Countries.filter((country) =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  deleteProvider() {
    if (!this.data.provider) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteProvider'),
          message: this.translate.instant('form.deleteProviderInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api.PROVIDERS_DeleteProvider(this.data.provider!.id).subscribe({
            next: (res) => {
              if (res.ok && res.body) {
                this.dialogRef.close({ reload: true });
                this.misc.showInfo(
                  this.translate.instant('success.deleteProvider', {
                    provider: this.data.provider?.name,
                  })
                );
              }
            },
            error: (err) => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
        }
      });
  }

  editProvider() {
    if (!this.data.provider || this.providerForm.invalid) return;
    this.api
      .PROVIDERS_PutProvider(this.providerForm.value as Provider)
      .subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.dialogRef.close({ reload: true });
            this.misc.showInfo(this.translate.instant('success.save'));
          }
        },
        error: (err) => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
  }

  addProvider() {
    if (this.providerForm.invalid) return;
    this.api
      .PROVIDERS_PostProvider(this.providerForm.value as Provider)
      .subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.dialogRef.close({ reload: true });
            this.misc.showInfo(
              this.translate.instant('success.addProvider', {
                provider: this.providerForm.get('name')?.value,
              })
            );
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: (err) => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
  }

  /* private loadUsers() {
    if (!this.data.provider) return;
    this.api.USER_GetUsers(this.data.provider?.id).subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.users = res.body;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
        this.isBusy = false;
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  openAddUser() {
    if (!this.data.provider) return;
    const dialogRef = this.dialog.open(ProviderAddUserComponent, {
      data: {
        provider: this.data.provider,
      },
            maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe((res) => this.loadUsers());
  } */

  deleteUser(user: User) {
    if (!user?.id || !this.data.provider?.id) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteProviderUser'),
          message: this.translate.instant('form.deleteProviderUserInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api
            .PROVIDERS_DeleteUser(this.data.provider!.id, user.id!)
            .subscribe({
              next: (res) => {
                if (res.ok) {
                  //this.loadUsers();
                  this.misc.showInfo(
                    this.translate.instant('success.deleteProviderUser')
                  );
                } else {
                  this.misc.showError(this.translate.instant('error.general'));
                }
              },
              error: (err) => {
                this.misc.showError(this.translate.instant('error.general'));
              },
            });
        }
      });
  }
}

/* @Component({
  selector: 'app-provider-add-user',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatTableModule,
    MatAutocompleteModule,
  ],
  templateUrl: 'provider-add-user.component.html',
})
export class ProviderAddUserComponent {
  user = new FormControl<User | null>(null, Validators.required);
  users: User[] = [];
  filteredUsers: User[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { provider: Provider },
    public dialogRef: MatDialogRef<ProviderAddUserComponent>,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!this.data.provider) return;
    this.api.USER_GetUsers().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.users = res.body;
          this.filteredUsers = res.body;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  filterUsers(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredUsers = this.users.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(filterValue)
    );
  }

  displayUserName(user: User): string {
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  save() {
    if (this.user.invalid || !this.data.provider) return;
    const _user = this.user.value;
    console.log(this.user.value);

    if (!_user || !_user.id) {
      return;
    }

    this.api.PROVIDERS_AddUser(this.data.provider.id, _user.id).subscribe({
      next: (res) => {
        if (res.ok) {
          this.misc.showInfo(
            this.translate.instant('success.addProviderUser', {
              userName: `${_user.firstName} ${_user.lastName}`,
            })
          );
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }
} */
