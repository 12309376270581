import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { RouterLink } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocationComponent } from './location/location.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Location } from '../../models/location';
import { MiscService } from '../../services/misc.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CountryPipe } from '../../pipes/country.pipe';
import {
  BlockScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
@Component({
  selector: 'app-locations',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatTableModule,
    MatButtonModule,
    ContentWrapperComponent,
    RouterLink,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    CountryPipe,
  ],
  providers: [CountryPipe],
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss',
})
export class LocationsComponent {
  data: any[] = [];
  dataSource: MatTableDataSource<Location> = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'name', 'street', 'city', 'country'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private misc: MiscService,
    private translate: TranslateService,
    public countryPipe: CountryPipe
  ) {}

  ngOnInit() {
    this.api.LOCATIONS_GetLocations().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.data = res.body;
          this.dataSource = new MatTableDataSource(res.body);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openLocationDialog(row?: Location) {
    const _dialog = this.dialog.open(LocationComponent, {
      data: row,
      maxHeight: '80vh',
    });
    _dialog
      .afterClosed()
      .subscribe((res: { reload: boolean }) =>
        res?.reload ? this.ngOnInit() : ''
      );
  }
}
