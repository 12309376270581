import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User = {};

  constructor(private router: Router) {
    const _userId = localStorage.getItem('userId');
    if (_userId != null) {
      this.user = { id: JSON.parse(_userId) };
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
}
