import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { NavigationItem } from '../models/navigation';
import { UserService } from '../services/user.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-private',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
  ],
  templateUrl: './private.component.html',
  styleUrl: './private.component.scss',
})
export class PrivateComponent {
  navigation: NavigationItem[] = [
    {
      label: 'SafeGuard',
      icon: 'security-outlined',
      path: ['/private', 'safeguard'],
    },
    {
      label: 'title.locations',
      icon: 'location_city-outlined',
      path: ['/private', 'locations'],
    },
    {
      label: 'title.providers',
      icon: 'apartment-outlined',
      path: ['/private', 'providers'],
    },
    {
      label: 'title.contacts',
      icon: 'call-outlined',
      path: ['/private', 'contacts'],
    },
    {
      label: 'title.racks',
      icon: 'grid_on-outlined',
      path: ['/private', 'racks'],
    },
    {
      label: 'title.products',
      icon: 'auto_awesome_motion-outlined',
      path: ['/private', 'products'],
    },
    {
      label: '',
    },
    {
      label: 'title.imprint',
      icon: 'badge-outlined',
      href: 'https://www.albrecht-automatik.de/impressum',
    },
    {
      label: 'title.privacy',
      icon: 'admin_panel_settings-outlined',
      href: 'https://www.albrecht-automatik.de/datenschutzerklaerung',
    },
    {
      label: '',
    },
    {
      label: 'title.profile',
      icon: 'person-outlined',
      path: ['/private', 'profile'],
    },
    {
      label: 'title.logout',
      icon: 'logout-outlined',
      action: () => {
        this.user.logout();
      },
    },
  ];

  constructor(private router: Router, private user: UserService) {}

  handleAction(item: NavigationItem) {
    switch (true) {
      case item?.action != null:
        item.action!();
        break;
      case item?.href != null:
        window.open(item.href, '_blank');
        break;
      case item?.path != null:
        this.router.navigate(item.path!);
        break;
      default: {
        console.error('Navigation action undefined');
      }
    }
  }
}
