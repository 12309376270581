<mat-drawer-container class="nav-wrapper">
    <mat-drawer class="nav-container" [disableClose]="true" mode="side" opened>

        <a href="/">
            <div class="logo"></div>
        </a>

        <mat-nav-list>
            @for (item of navigation; track item.path) {
            <a mat-list-item (click)="handleAction(item)" routerLinkActive="path-active" [disabled]="item.label == ''">
                <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
                <span matListItemTitle>{{item.label | translate}}</span>
            </a>
            }
        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
