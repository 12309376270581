import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MiscService } from '../../services/misc.service';
import { Login } from '../../models/login';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loginForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    private router: Router
  ) {}

  async login() {
    if (this.loginForm.invalid) return;
    this.api.AUTH_Login(this.loginForm.value as Login).subscribe({
      next: (_res) => {
        if (_res.ok && _res.body) {
          localStorage.setItem('access_token', _res.body.access_token);

          this.api.USER_GetUser(_res.body.id).subscribe({
            next: (_res) => {
              if (_res.ok && _res.body) {
                localStorage.setItem('userId', JSON.stringify(_res.body.id));
                this.router.navigate(['/private', 'dashboard']);
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            },
            error: (err) => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }
}
