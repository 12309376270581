@defer (when !isBusy) {
<app-content-wrapper [title]="location?.id ? ('title.location' | translate:{ location: location!.name }) : ('title.addLocation' | translate)">
    <button mat-icon-button toolbar (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    <div class="aa-flex" content>
        <form class="aa-form" style="width: 350px" [formGroup]="locationForm">
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.locationId' | translate}}</mat-label>
                    <input formControlName="locationId" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.name' | translate}}</mat-label>
                    <input formControlName="name" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.street' | translate}}</mat-label>
                    <input formControlName="street" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.city' | translate}}</mat-label>
                    <input formControlName="city" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.country' | translate}}</mat-label>
                    <input type="text" formControlName="country" matInput [matAutocomplete]="auto" (input)="filterCountries($event)">
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryPipe.transform" requireSelection>
                    @for (country of filteredCountries; track country.code) {
                    <mat-option [value]="country.alpha2">{{country.name}}</mat-option>
                    }
                </mat-autocomplete>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.provider' | translate}}</mat-label>
                    <mat-select formControlName="providerId">
                        @for (item of providers; track item.id) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.contact' | translate}}</mat-label>
                    <mat-select formControlName="contactId">
                        @for (item of contacts; track item.id) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.language' | translate}}</mat-label>
                    <mat-select formControlName="language">
                        @for (item of languages; track item.id) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.adminPassword' | translate}}</mat-label>
                    <input formControlName="adminPassword" matInput #pwa type="password">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="pwa.type = (pwa.type == 'text') ? 'password' : 'text'">
                        <mat-icon>{{pwa.type == 'text' ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.intervalPasswordA' | translate}}</mat-label>
                    <input formControlName="intervalPasswordA" matInput [type]="showPwdA ? 'text' : 'password'">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="showPwdA = !showPwdA">
                        <mat-icon>{{showPwdA ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.intervalPasswordB' | translate}}</mat-label>
                    <input formControlName="intervalPasswordB" matInput [type]="showPwdB ? 'text' : 'password'">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="showPwdB = !showPwdB">
                        <mat-icon>{{showPwdB ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </p>
            <button mat-raised-button color="primary" (click)="save()" [disabled]="locationForm.invalid || locationForm.pristine">
                <mat-icon>save</mat-icon>
                {{'form.save' | translate}}
            </button>
            @if (location?.id) {
            <br /><br />
            <button mat-raised-button (click)="deleteLocation()">
                <mat-icon>delete</mat-icon>
                {{'form.deleteLocation' | translate}}
            </button>
            }

        </form>
        @if (location?.id) {
        <div class="details-container">
            <app-racks (onSelectRack)="changeProducts($event)" (onUpdateRack)="ngOnInit()" [racks]="location?.racks" [location]="location"
                origin="locations"></app-racks>
            <app-products [products]="products" origin="locations" #productList [location]="location"
                (onUpdateProduct)="ngOnInit(activatedRackId)"></app-products>
        </div>
        }
    </div>
</app-content-wrapper>
}
@placeholder {
<mat-progress-bar mode="indeterminate" style="width: 20vw;"></mat-progress-bar>
}
