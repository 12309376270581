<div class="login-wrapper">
    <div class="login-container">

        <a href="/">
            <div class="logo"></div>
        </a>

        <form class="aa-form" style="margin-top:20px" [formGroup]="loginForm" (submit)="login()">
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>E-Mail-Adresse</mat-label>
                    <input type="email" formControlName="email" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <input type="password" formControlName="password" matInput #pw>
                    <mat-icon inline matSuffix (click)="pw.type = pw.type == 'password' ? 'text' : 'password'"
                        style="cursor:pointer">{{pw.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </p>

            <button mat-raised-button color="primary" [disabled]="loginForm.invalid || loginForm.pristine">
                <mat-icon>save</mat-icon>
                Login
            </button>
        </form>
    </div>
</div>
