<app-content-wrapper [title]="'title.locations' | translate">
    <button mat-icon-button toolbar (click)="openLocationDialog()"><mat-icon>add</mat-icon></button>
    <div content>
        <mat-form-field appearance="outline">
            <mat-label>Filter...</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
            <!-- Number Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.locationId' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.locationId}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.name' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <!-- Street Column -->
            <ng-container matColumnDef="street">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.street' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.street}}</td>
            </ng-container>

            <!-- City Column -->
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                    {{'form.city' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.city}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                    {{'form.country' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.country | countryName}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd" (click)="openLocationDialog(row)"
                [ngClass]="odd ? 'odd-row' : ''">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    <div class="table-empty-row">
                        {{'form.emptyData' | translate}}
                    </div>
                </td>
            </tr>
        </table>
        <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
    </div>
</app-content-wrapper>
