import { Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { PrivateComponent } from './private/private.component';
import { authGuard } from './services/auth.guard';
import { SafeGuardComponent } from './private/safe-guard/safe-guard.component';
import { LocationsComponent } from './private/locations/locations.component';
import { ProfileComponent } from './private/profile/profile.component';
import { LocationComponent } from './private/locations/location/location.component';
import { ContactsComponent } from './private/contacts/contacts.component';
import { ProvidersComponent } from './private/providers/providers.component';
import { RacksComponent } from './private/racks/racks.component';
import { ProviderComponent } from './private/providers/provider/provider.component';
import { ProductsComponent } from './private/products/products.component';
import { ContactComponent } from './private/contacts/contact/contact.component';
import { RackComponent } from './private/racks/rack/rack.component';
import { ProductComponent } from './private/products/product/product.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'private',
    canActivateChild: [authGuard],
    component: PrivateComponent,
    children: [
      {
        path: 'safeguard',
        component: SafeGuardComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'locations',
        component: LocationsComponent,
      },
      {
        path: 'locations/:locationId',
        component: LocationComponent,
      },
      {
        path: 'providers',
        component: ProvidersComponent,
      },
      {
        path: 'providers/:providerId',
        component: ProviderComponent,
      },
      {
        path: 'contacts',
        component: ContactsComponent,
      },
      {
        path: 'contacts/:contactId',
        component: ContactComponent,
      },
      {
        path: 'racks',
        component: RacksComponent,
      },
      {
        path: 'racks/:rackId',
        component: RackComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      {
        path: 'products/:productId',
        component: ProductComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/private/safeguard',
  },
];
