<app-content-wrapper title="SafeGuard">
    <div content class="split-vertical">
        <div class="split-horizontal">
            <div class="logo"></div>
            <img [src]="safeGuardQR" width="256" height="256" />
            <a mat-raised-button color="primary" href="./97483c89-1ad3-46fc-a3d3-6a736ae18ad2/safeguard.apk"
                download>{{'form.download' | translate}}</a>
        </div>
    </div>
</app-content-wrapper>
