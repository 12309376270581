import { Pipe, PipeTransform } from '@angular/core';
import Countries from '../../assets/countryCodes.json';

@Pipe({
  name: 'countryName',
  standalone: true,
})
export class CountryPipe implements PipeTransform {
  transform(countryCode: string): string {
    return Countries.find((e) => e.alpha2 === countryCode)?.name ?? '';
  }
}
