<app-content-wrapper
    [title]="data?.contact ? ('title.contact' | translate: { contact: contactForm.get('name')?.value }) : 'title.addContact' | translate">
    <button mat-icon-button toolbar (click)="closeDialog()"><mat-icon>close</mat-icon></button>
    <div content>
        <form class="aa-form" [formGroup]="contactForm">
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.company' | translate}}</mat-label>
                    <input formControlName="name" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.street' | translate}}</mat-label>
                    <input formControlName="street" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.city' | translate}}</mat-label>
                    <input formControlName="city" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.country' | translate}}</mat-label>
                    <input type="text" formControlName="country" matInput [matAutocomplete]="auto" (input)="filterCountries($event)">
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryPipe.transform" requireSelection>
                    @for (country of filteredCountries; track country.code) {
                    <mat-option [value]="country.alpha2">{{country.name}}</mat-option>
                    }
                </mat-autocomplete>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.email' | translate}}</mat-label>
                    <input type="email" formControlName="email" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.phone' | translate}}</mat-label>
                    <input type="text" formControlName="phone" maxlength="50" matInput>
                </mat-form-field>
            </p>

            @if(data?.contact != null) {
            <button mat-raised-button color="primary" (click)="editContact()" [disabled]="contactForm.invalid || contactForm.pristine">
                <mat-icon>save</mat-icon>
                {{'form.save' | translate}}
            </button>

            <br /><br />
            <button mat-raised-button (click)="deleteContact()">
                <mat-icon>delete</mat-icon>
                {{'form.deleteContact' | translate}}
            </button>
            } @else {

            <button mat-raised-button color="primary" [disabled]="contactForm.invalid || contactForm.pristine" (click)="addContact()">
                <mat-icon>plus</mat-icon>
                {{'form.save' | translate}}
            </button>
            }
        </form>
    </div>
</app-content-wrapper>
