import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentWrapperComponent } from '../../../components/content-wrapper/content-wrapper.component';
import { ApiService } from '../../../services/api.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { MiscService } from '../../../services/misc.service';
import { Contact } from '../../../models/contact';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import Countries from '../../../../assets/countryCodes.json';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CountryPipe } from '../../../pipes/country.pipe';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    TranslateModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [CountryPipe],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  contactForm = this.fb.group({
    id: [-1],
    name: ['', [Validators.required, Validators.maxLength(50)]],
    street: ['', [Validators.required, Validators.maxLength(50)]],
    city: ['', [Validators.required, Validators.maxLength(50)]],
    country: ['', Validators.required],
    email: ['', [Validators.email, Validators.maxLength(50)]],
    phone: ['', [Validators.pattern('[0-9]+'), Validators.maxLength(50)]],
  });

  activatedRow: any;
  displayedColumns = ['name', 'email', 'phone'];
  filteredCountries: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { contact?: Contact },
    public dialogRef: MatDialogRef<ContactComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public countryPipe: CountryPipe
  ) {}

  ngOnInit(): void {
    if (this.data?.contact) {
      this.contactForm.patchValue(this.data.contact);
    }
  }

  filterCountries(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredCountries = Countries.filter((country) =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteContact() {
    if (!this.data.contact) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteContact'),
          message: this.translate.instant('form.deleteContactInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api.CONTACTS_DeleteContact(this.data.contact?.id!).subscribe({
            next: (res) => {
              if (res.ok && res.body) {
                this.dialogRef.close({ reload: true });
                this.misc.showInfo(
                  this.translate.instant('success.deleteContact', {
                    contact: this.data.contact?.name,
                  })
                );
              }
            },
            error: (err) => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
        }
      });
  }

  editContact() {
    if (this.contactForm.invalid) return;
    this.api.CONTACTS_PutContact(this.contactForm.value as Contact).subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.dialogRef.close({ reload: true });
          this.misc.showInfo(this.translate.instant('success.save'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  addContact() {
    if (this.contactForm.invalid) return;
    this.api.CONTACTS_PostContact(this.contactForm.value as Contact).subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.dialogRef.close({ reload: true });
          this.misc.showInfo(
            this.translate.instant('success.createContact', {
              contact: this.contactForm.get('name')?.value,
            })
          );
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }
}
