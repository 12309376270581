import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from '../../services/api.service';
import { User, UserPassword } from '../../models/user';
import { MiscService } from '../../services/misc.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ContentWrapperComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  profileForm = this.fb.group({
    id: [0],
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    email: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(50)],
    ],
    phone: ['', [Validators.pattern('[0-9]+'), Validators.maxLength(50)]],
  });
  passwordForm = this.fb.group({
    password: ['', [Validators.required, Validators.maxLength(200)]],
    passwordNew: ['', [Validators.required, Validators.maxLength(200)]],
  });

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.userService.user?.id == null) return;
    this.api.USER_GetUser(this.userService.user.id).subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.profileForm.patchValue(res.body);
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  saveProfile() {
    if (this.profileForm.invalid) return;

    this.api.USER_PutUser(this.profileForm.value as User).subscribe({
      next: (res) => {
        if (res.ok) {
          this.misc.showInfo(this.translate.instant('success.patchUser'));
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  changePassword() {
    if (this.passwordForm.invalid) return;

    this.api
      .AUTH_ChangePassword(this.passwordForm.value as UserPassword)
      .subscribe({
        next: (res) => {
          if (res.ok) {
            this.passwordForm.reset();
            this.misc.showInfo(
              this.translate.instant('success.changePassword')
            );
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: (err) => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
  }
}
