import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { MiscService } from './misc.service';

export const authGuard: CanActivateChildFn = (route, state) => {
  const _userService = inject(UserService);
  const _router = inject(Router);
  const _jwt = inject(JwtHelperService);
  const _misc = inject(MiscService);

  if (!_jwt || _jwt.isTokenExpired() || _userService.user == null) {
    localStorage.clear();
    _misc.showInfo(
      'Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.'
    );
    return _router.createUrlTree(['/login']);
  } else {
    return true;
  }
};
