<app-content-wrapper [title]="'title.providers' | translate" style="flex:1">
    <button mat-icon-button toolbar (click)="addProvider()"><mat-icon>add</mat-icon></button>

    <div content>
        <mat-form-field appearance="outline">
            <mat-label>Filter...</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>

        <table content mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="created" matSortDisableClear
            matSortDirection="desc">

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.company' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="street">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.street' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.street}}</td>
            </ng-container>

            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                    {{'form.city' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.city}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                    {{'form.country' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.country | countryName}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd;" (click)="editProvider(row); activatedRow = row"
                [ngClass]="[odd ? 'odd-row' : '', activatedRow == row ? 'activated-row' : '']"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    <div class="table-empty-row">
                        {{'form.emptyData' | translate}}
                    </div>
                </td>
            </tr>
        </table>
        <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
    </div>
</app-content-wrapper>
